/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ImgGridCard from '../components/ImgGridCard'

const Smoothies = ({ data }) => {
  return (
    <Layout>
      <SEO title="Fitness and Spirit- Smoothie Recipes" />
      <div className="container-main">
        <div className="container-main_div">
          <h1>Smoothies</h1>
          <h2>
            “Let food be thy medicine and medicine be thy food be thy medicine.”
            ~Hippocrites
          </h2>
          <p>
            <strong>Smoothies </strong>
            are an easy and nutritious way to start your day for breakfast, to
            have as a snack, or even as a meal replacement. Within just a few
            minutes you can add multiple servings of fruit, veggies, protein,
            healthy fat and more!
          </p>
          <p>
            <strong>Blenders </strong>
            and textures may vary. My personal experience with blenders have
            been with the Magic Bullet and currently the Vitamix. The Magic
            Bullet was on the cheaper side and convenient. The Vitamix was a
            larger investment and well worth it.
            <strong> Protein Sources </strong>
            can be very personal in what your palette requires, and can come
            from a variety of options. For example: raw egg whites, nuts,
            veggies, chia seeds, whey protein powder, collagen protein powder,
            plant based protein powder, flavored protein powder, and more.
            Personally, my palette cannot handle flavored protein with a chalky
            flavor. For many years, I used Isopure unflavored whey protein.
            Recently, I fell in love with Vital Proteins Original Collagen
            Peptides. This protein has no flavor and I use it in my smoothies,
            workout drinks, and even cook with it.
          </p>
          <p>
            <strong>Making a smoothie </strong>
            can be done with three simple steps:
          </p>
          <ol>
            <li>
              Gather Ingredients- Start with your why. What is the purpose of
              your smoothie. Is it a meal replacement, a snack, a protein
              source?
            </li>
            <li>Blend - Start with a liquid and your leafy veggies.</li>
            <li>
              Serve- Reflect on your measurements according to your serving
              needs. I tend to make more than I need.
            </li>
          </ol>
        </div>
      </div>
      <section className="text-gray-700 body-font">
        <div className="container md:px-8 md:mx-auto  lg:px-4">
          <div className="flex flex-wrap text-left content-between">
            <ImgGridCard
              image={data.gGreen.childImageSharp.fluid}
              title="Grape & Green"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li> ¾ cup Coconut water</li>
                <li>¾ cup Red Grapes</li>
                <li>1 banana</li>
                <li>1 cup of spinach or any other leafy greens</li>
                <li>1 small cucumber</li>
                <li>½ cup of ice (optional)</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.iGreen.childImageSharp.fluid}
              title="Island Green"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>1 mango</li>
                <li>1 banana</li>
                <li>½ avocado</li>
                <li>1 cup spinach</li>
                <li>½ cup (4 oz; 120 mL) coconut water</li>
                <li>½ cup of ice (optional)</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.strBanana.childImageSharp.fluid}
              title="Strawberry Banana Treat"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>1 cup coconut milk</li>
                <li>½ cup coconut water</li>
                <li>4 bananas</li>
                <li>1 ½ cup strawberries</li>
                <li>1 tsp honey</li>
                <li>½ cup of ice (optional)</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.bMango.childImageSharp.fluid}
              title="Very Berry Mango"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>½ cup Strawberries</li>
                <li>½ cup Blueberries</li>
                <li>½ cup Raspberries</li>
                <li>½ cup Blackberries</li>
                <li>½ Mango</li>
                <li>½ Avocado</li>
                <li>4 oz. unsweetened coconut water</li>
                <li>½ cup ice (if using fresh fruit)</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.pomegrant.childImageSharp.fluid}
              title="Pomegranate Chard"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li> ½ cup frozen Pomegranate Seeds</li>
                <li>1 cup Blueberries</li>
                <li>
                  1 cup Berry of Your Choice (blackberry, cherry, raspberry,
                  strawberry)
                </li>
                <li>5 leaves Rainbow Swiss Chard</li>
                <li>4 oz unsweetened Coconut Water</li>
                <li>½ cup ice (if using fresh fruit)</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.greatGreen.childImageSharp.fluid}
              title="Great Green"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>
                  2 cups Beet Greens, Red Swiss Chard, Kale, Spinach or a
                  combination,
                </li>
                <li>1 Banana</li>
                <li>1 Golden Delicious Apple</li>
                <li>½ Comice Pear</li>
                <li>10 Strawberries</li>
                <li>1 cup Coconut Water</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.bBerry.childImageSharp.fluid}
              title="Blackberry"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>¼ large Pineapple, roughly cubed</li>
                <li>1 cup Blackberries</li>
                <li>1 Kiwi Fruit, peeled</li>
                <li>1 Banana, peeled</li>
                <li>½ Comice Pear</li>
                <li>½ cup Coconut Water</li>
                <li>30 Mint leaves</li>
                <li>1 tbsp Flax seed oil (optional)</li>
              </ul>
            </ImgGridCard>
            <ImgGridCard
              image={data.citrus.childImageSharp.fluid}
              title="Citrus"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>1 Lemon </li>
                <li>1 Clementine </li>
                <li>¼ cup Mango </li>
                <li>¼ cup Carrots </li>
                <li>1 cup Spinach </li>
                <li>¼ cup Green Goodness</li>
                <li>1 serving Protein</li>
              </ul>
            </ImgGridCard>

            <ImgGridCard
              image={data.papaya.childImageSharp.fluid}
              title="Papaya Power"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>½ cup Papaya </li>
                <li>1 cup Spinach </li>
                <li>1 banana</li>
                <li>¼ cup Green Goodness </li>
                <li>1 Mandarin </li>
                <li>1 serving Protein </li>
              </ul>
            </ImgGridCard>
            <ImgGridCard
              image={data.bBerry2.childImageSharp.fluid}
              title="Blackberry Badass"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li> ¼ cup Blackberries </li>
                <li>1 Banana </li>
                <li>1 Lime </li>
                <li>1 cup Spinach </li>
                <li>¼ cup Green goodness </li>
                <li>1 serving Protein powder </li>
              </ul>
            </ImgGridCard>
            <ImgGridCard
              image={data.gGrape.childImageSharp.fluid}
              title="Great Grape"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>½ cup Grapes </li>
                <li>¼ cup Strawberries </li>
                <li> ¼ cup Carrots</li>
                <li>1 cup Spinach </li>
                <li>¼ cup Green goodness </li>
                <li>1 serving Protein powder </li>
              </ul>
            </ImgGridCard>
            <ImgGridCard
              image={data.jolly.childImageSharp.fluid}
              title="Green Jollyrancher"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>1 Green apple </li>
                <li>1 cup Spinach</li>
                <li>½ Cucumber </li>
                <li>1 Lime </li>
                <li>¼ cup Green goodness </li>
                <li>2 oz Egg whites </li>
                <li>¼ cup Sprite </li>
              </ul>
            </ImgGridCard>
            <ImgGridCard image={data.pButter.childImageSharp.fluid} title="P&J">
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>1tbl Peanut Butter </li>
                <li>½ cup Frozen strawberries </li>
                <li>1 Banana </li>
                <li>½ cup Green goodness </li>
                <li>1 cup Spinach </li>
                <li>1 serving Protein</li>
              </ul>
            </ImgGridCard>
            <ImgGridCard image={data.red.childImageSharp.fluid} title="Rad Red">
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>¼ cup Raspberries</li>
                <li>½ cup Frozen strawberries </li>
                <li>1 Red apple </li>
                <li>1 cup Spinach </li>
                <li>4oz Green Goodness </li>
                <li>1 serving Protein </li>
              </ul>
            </ImgGridCard>
            <ImgGridCard
              image={data.orange.childImageSharp.fluid}
              title="Orange Goodness"
            >
              <ul className="mb-4 text-base leading-relaxed w-4/5 pl-2">
                <li>¼ cup Frozen mango </li>
                <li>¼ cup Peach </li>
                <li>½ cup Papaya </li>
                <li>¼ cup Carrots </li>
                <li> ¼ cup Pineapple</li>
                <li>1 cup Spinach </li>
                <li>4oz Green Goodness </li>
                <li>1 serving Protein</li>
              </ul>
            </ImgGridCard>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    gGrape: file(relativePath: { eq: "smoothies/great grape.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bBerry2: file(relativePath: { eq: "smoothies/blackberry badass.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bBerry: file(relativePath: { eq: "smoothies/blackberry.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    citrus: file(relativePath: { eq: "smoothies/citrus.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    gGreen: file(relativePath: { eq: "smoothies/grape-green.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    jolly: file(relativePath: { eq: "smoothies/green jollyrancher.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    iGreen: file(relativePath: { eq: "smoothies/island green.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    orange: file(relativePath: { eq: "smoothies/orange goodness.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    papaya: file(relativePath: { eq: "smoothies/papaya.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pButter: file(relativePath: { eq: "smoothies/peanutbutter jelly.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    pomegrant: file(relativePath: { eq: "smoothies/pomegrant chard.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    red: file(relativePath: { eq: "smoothies/rad red.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bMango: file(relativePath: { eq: "smoothies/very berry mango.png" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    strBanana: file(relativePath: { eq: "smoothies/straw ban.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    greatGreen: file(
      relativePath: { eq: "smoothies/great green smoothie.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 320, maxWidth: 320, cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Smoothies
