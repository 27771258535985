import React from 'react'
import Img from 'gatsby-image'

const ImgGridCard = ({ image, title, children }) => {
  return (
    <div className="py-6 2xl:1/4  xl:w-1/3 lg:w-1/2  md:mx-auto">
      <div className="m-0 pb-5 lg:mx-8 rounded-md md:shadow-lg">
        <Img fluid={image} alt="" />
        <div className=" w-96 h-72 mx-6">
          <h2 className="mb-3 text-lg font-semibold text-gray-700 lg:text-2xl title-font ml-5">
            {title}
          </h2>
          {children}
        </div>
      </div>
    </div>
  )
}

export default ImgGridCard
